<template>
    <div>
        <full-overlay :show="loading" />
        <b-table :items="list" :fields="fields" striped class="mt-3">
            <template #cell(place)="{ value: { name } }">
                <div>{{ name.ru }}</div>
                <div>{{ name.en }}</div>
            </template>
            <template
                #cell(spectacle)="{
                    value: {
                        spectacle: { name },
                    },
                }"
            >
                <div>{{ name.ru }}</div>
                <div>{{ name.en }}</div>
            </template>
            <template #cell(settings)="{ item: { id } }">
                <b-button-group>
                    <b-button @click="edit(id)">
                        <b-icon icon="pencil" />
                    </b-button>
                    <b-button @click="remove(id)" variant="danger">
                        <b-icon icon="trash" />
                    </b-button>
                </b-button-group>
            </template>
        </b-table>
        <b-modal
            title="Редактирование"
            v-model="modal.show"
            hide-footer
            size="lg"
        >
            <b-form @submit.prevent="send">
                <b-form-row>
                    <b-col>
                        <b-form-group label="Спектакль">
                            <b-form-select
                                v-model="modal.spectacle"
                                :options="spectacle"
                                value-field="id"
                                text-field="spectacle.name.ru"
                                required
                            >
                                <template #first>
                                    <b-form-select-option
                                        :value="null"
                                        disabled
                                    >
                                        -- Выберите спектакль --
                                    </b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group label="Место">
                            <b-form-select
                                v-model="modal.place"
                                :options="place"
                                value-field="id"
                                text-field="name.ru"
                                required
                            >
                                <template #first>
                                    <b-form-select-option
                                        :value="null"
                                        disabled
                                    >
                                        -- Выберите место --
                                    </b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                </b-form-row>
                <b-form-row>
                    <b-col>
                        <b-form-group label="Дата">
                            <b-form-datepicker v-model="modal.date" required />
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group label="Время">
                            <b-form-timepicker v-model="modal.time" required />
                        </b-form-group>
                    </b-col>
                </b-form-row>
                <b-button type="submit" class="mt-1">Сохранить</b-button>
            </b-form>
        </b-modal>
    </div>
</template> 

<script>
import FullOverlay from "@c/FullOverlay.vue";
export default {
    components: { FullOverlay },
    name: "FestivalCard",
    props: {
        id: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
            list: [],
            spectacle: [],
            place: [],
            fields: [
                {
                    key: "id",
                    label: "#",
                },
                {
                    key: "place",
                    label: "Место",
                },
                {
                    key: "spectacle",
                    label: "Спектакль",
                },
                {
                    key: "date",
                    label: "Время проведения",
                },
                {
                    key: "settings",
                    label: "",
                },
            ],
            modal: this.getModal(),
        };
    },
    mounted() {
        this.update();
    },
    methods: {
        async send() {
            this.loading = true;
            const body = new FormData();
            body.append("id", this.modal.id);
            body.append("spectacle", this.modal.spectacle);
            body.append("place", this.modal.place);
            body.append("date", this.modal.date);
            body.append("time", this.modal.time);
            await this.$fetchAdmin(`/festival/${this.id}/action`, {
                method: "POST",
                body,
            }).then((r) => r.json());
            this.loading = false;
            this.modal.show = false;
            this.update();
        },
        async edit(id) {
            this.loading = true;
            const { one } = await this.$fetchAdmin(
                `/festival/action/${id}`
            ).then((r) => r.json());
            this.modal = Object.assign(this.getModal(), one);
            this.modal.show = true;
            this.loading = false;
        },
        async update() {
            this.loading = true;
            const { list, spectacle, place } = await this.$fetchAdmin(
                `/festival/${this.id}/action`
            ).then((r) => r.json());
            this.list = list;
            this.spectacle = spectacle;
            this.place = place;
            this.loading = false;
        },
        getModal: () => ({
            id: 0,
            show: false,
            place: null,
            spectacle: null,
            date: "",
            time: "",
        }),
        add() {
            this.modal = this.getModal();
            this.modal.show = true;
        },
        async remove(id) {
            const st = await this.$bvModal.msgBoxConfirm(
                "Подтвердите удаление"
            );
            if (!st) {
                return false;
            }
            this.loading = true;
            await this.$fetchAdmin(`/festival/action/${id}`, {
                method: "DELETE",
            }).then((r) => r.json());
            this.loading = false;
            this.update();
        },
    },
};
</script>